import { APP_BASE_URL } from 'config/constants'
import { defaultSession, type SessionDataType } from 'lib/session/config'

export type ErrorLogNameType = 'axios-server-error' | 'api-route-error' | 'server-error'

const maskPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return ''

  // mask last 4 digits
  return `${phoneNumber.substring(0, phoneNumber.length - 4)}****`
}

export const getUserCtx = (userSession: SessionDataType) => ({
  usr: {
    userId: userSession.user?.id,
    phoneNumber: maskPhoneNumber(userSession?.user?.phone_number) || '',
    isLoggedIn: userSession.isLoggedIn,
    hasToken: !!userSession.token,
  },
})

export const getCommonErrorServerObject = ({
  message,
  error,
  otherAttrs = {},
  sessionData = defaultSession,
}: {
  message?: string
  error: Error
  otherAttrs?: Record<string, unknown>
  sessionData: SessionDataType
}) => {
  const errName = error?.name ? `${error?.name}: ` : ''
  const customMessage = message ? `${message} ` : ''

  const parsedMessage = `${customMessage || ''}${errName}${error?.message || ''}`

  return {
    level: 'error',
    message: parsedMessage,
    error: {
      ...(error || {}),
      name: error?.name || '',
      message: error?.message || '',
      stack: error?.stack || '',
      cause: error?.cause || '',
    },
    customInfo: {
      origin: 'server-error',
      hostname: APP_BASE_URL || ``,
    },
    ...getUserCtx(sessionData),
    ...otherAttrs,
  }
}

export const getCommonInfoServerObject = ({
  message,
  info = {},
  sessionData,
}: {
  message: string
  info?: Record<string, unknown>
  sessionData: SessionDataType
}) => ({
  level: 'info',
  message,
  customInfo: {
    origin: 'server-info',
    hostname: APP_BASE_URL || ``,
    ...info,
  },
  ...getUserCtx(sessionData),
})
