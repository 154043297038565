'use client'

import { PropsWithChildren } from 'react'
import { SessionStateType } from 'store/user'
import dynamic from 'next/dynamic'

const SessionStoreProvider = dynamic(
  () => import('store/user/sessionStoreProvider').then((mod) => mod.SessionStoreProvider),
  { ssr: false },
)

export type ClientStoreProviderType = PropsWithChildren & {
  initState: SessionStateType
}

const ClientStoreProvider = ({ children, initState }: ClientStoreProviderType) => (
  <SessionStoreProvider initState={initState}>{children}</SessionStoreProvider>
)

export default ClientStoreProvider
