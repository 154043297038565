'use client'

import { useEffect, useState } from 'react'
import {
  initDatadogLog,
  setUserData as setUserLogCtx,
} from '@astronautsid/wpe-utils/helpers/datadog'
import { useShallow } from 'zustand/react/shallow'

import useAuthStore, { type AuthSliceType } from 'store/auth'
import useUserStore from 'store/user'
import {
  ENABLE_DATADOG,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  DATADOG_SESSION_SAMPLE_RATE,
  APP_NAME,
  APP_VERSION,
  ENV,
} from 'config/constants'
import { useSearchParams } from 'next/navigation'
import { getUserCtx } from 'lib/datadog'
import { SessionDataType } from 'lib/session/config'

const selectAuthStore = ({ auth, webviewSessionProps }: AuthSliceType) => ({
  auth,
  webviewSessionProps,
})

const useBrowserLog = () => {
  const { auth: authWebview, webviewSessionProps } = useAuthStore(useShallow(selectAuthStore))
  const sessionData = useUserStore((state) => state)

  const { user: currentUser, isLoggedIn } = sessionData

  const [hasInitDatadog, setHasInitDatadog] = useState(false)

  const searchParams = useSearchParams()

  const isWebview = searchParams.get('webview') === 'true'

  useEffect(() => {
    if (!hasInitDatadog) {
      return
    }

    /**
     * Set User ctx for webview user
     */
    if (isWebview && authWebview.isAuthenticated) {
      const { user } = authWebview

      setUserLogCtx(
        getUserCtx({
          userId: user.id,
          phoneNumber: user.phoneNumber,
          isLoggedIn: authWebview.isAuthenticated,
          token: webviewSessionProps.nativeHeaders?.AUTHORIZATION
            ? webviewSessionProps.nativeHeaders?.AUTHORIZATION.split(' ')[1]
            : '',
        } as unknown as SessionDataType).usr,
      )

      return
    }

    /**
     * Set User ctx for default user
     */
    setUserLogCtx(getUserCtx(sessionData).usr)
  }, [
    authWebview,
    hasInitDatadog,
    currentUser,
    isWebview,
    isLoggedIn,
    sessionData,
    webviewSessionProps.nativeHeaders?.AUTHORIZATION,
  ])

  useEffect(() => {
    if (!ENABLE_DATADOG) {
      return
    }

    initDatadogLog({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      forwardErrorsToLogs: false,
      sessionSampleRate: Number(DATADOG_SESSION_SAMPLE_RATE),
      env: ENV === 'production' ? 'production' : 'development',
      service: APP_NAME,
      version: APP_VERSION,
    })

    setHasInitDatadog(true)
  }, [])
}

export default useBrowserLog
